.app {
  font-size: 14px;
  --primary-color: #df4a24;
  --secondary-color: #fbfccf;
  padding-bottom: 20px;

  .header {
    background: linear-gradient(
      180deg,
      #f0ca68 0%,
      #e58835 43.29%,
      #df4a24 100%
    );
  }
}

.iws.app {
  .header {
    background-image: linear-gradient(
      to top,
      #00562e,
      #006835,
      #007a3b,
      #008d40,
      #00a045
    );
  }
}

.main-loading {
  padding: 30px;
  text-align: center;
}

a {
  color: inherit !important;
  text-decoration: none;
}

.header {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding: 0 10px;

  &__icon {
    font-size: 18px;
    color: #fff;
  }

  &__text {
    text-transform: uppercase;
    font-weight: bold;
  }
}

.point-event {
  padding: 0 10px;
  margin-top: 8px;
  .main-item {
    background: var(--secondary-color);
    border-radius: 20px;
    padding: 16px;
    font-weight: 600;
    margin-bottom: 50px;

    &__name {
      font-size: 20px;
    }

    &__rank {
      font-size: 14px;
    }

    &__footer {
      text-align: right;
    }
  }

  .sub-item {
    background: var(--secondary-color);
    padding: 16px;
    border-radius: 20px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__desc span {
      font-size: 18px;
      font-weight: bold;
    }
  }
}

.point-event-detail {
  padding: 0 10px;
  margin-top: 8px;

  .heading-box {
    background: var(--secondary-color);
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;

    & span {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .info {
    margin-bottom: 10px;
  }
}

.tabs {
  &__item {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    text-align: center;
    display: block;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary-color);
  }

  .ant-tabs-tab:hover {
    color: var(--primary-color);
  }

  .ant-tabs-ink-bar {
    background-color: var(--primary-color);
  }
}

.custom-antd {
  .ant-spin-dot-item {
    background-color: var(--primary-color);
  }

  .highlight-row-table {
    background-color: var(--secondary-color);
  }
}
